import React from "react";
import {images} from "../components/MediaLib";
import {ContactDetails} from "../views";
import {CompanyData} from "../content";

export const ContactUs = () => {
    // Translation temporarily disabled
    //const {t} = useTranslation();
    const t = (txt)=>txt;
    return (
        <ContactDetails
            title={t("Contact details")}
            subtitle={t("We look forward to hearing from you.")}
            details={[
                {
                    title:t("E-mail"),
                    detail:CompanyData.email,
                    icon: images.contact_mail_icon,
                    icon2: images.contact_mail_icon2
                },
                {
                    title:t("Phone"),
                    detail:CompanyData.phone,
                    icon: images.contact_phone_icon,
                    icon2: images.contact_phone_icon2
                },
                {
                    title:t("Address"),
                    detail:t(CompanyData.short_address),
                    icon: images.contact_pin_icon,
                    icon2: images.contact_pin_icon2
                },
                {
                    title:t("TAX ID:"),
                    detail:CompanyData.VAT,
                    icon: images.check_icon
                },
            ]}
        />
    );

};