
// TODO: i18next breaks AOS animations, but not clue why.
// An AOS.refresh() at the right time could be the fix. but it is not clear

import React from 'react';
import {Divider} from '@material-ui/core';
import { graphql } from 'gatsby';

import Main from 'layouts/Main';
import WithLayout from 'layouts/WithChildrenLayout';
import { Section, SectionAlternate } from 'components/organisms';


import {CompanyData, Services,InnovationWorkflow, PeopleInTechProcess, TechleadProcess, OpenSourceStacks} from 'content';

import {images} from "../components/MediaLib";

import {Hero, Features, WorkFlow, Partners, ContactDetails} from 'views';
import {Process} from "../views";
import {SEO} from "components/seo";
import {ContactUs} from "../partials";

const IndexPage = (props) => {
    // Translation temporarily disabled
    //const {t} = useTranslation();
    const t = (txt)=>txt;
    return (
     <WithLayout layout={Main}>
        <SEO title={t("Valletech")} />
        <Hero
            title={t("Launch exciting new products")}
            subtitle={t("Build top teams that deliver")}
            banner_image={images.develop_banner}
            banner_image_dk={images.develop_banner_dk}
            action={t("Contact Us")}
            href="/contact"
            />
         <Divider/>
         <Section>
             <Features
                 title={t("How we help")}
                 features={Services}
                 action={t("Contact Us")}
                 href="/contact"
             />
         </Section>
         <Divider/>
         <SectionAlternate>
             <WorkFlow
                 title={t("Innovate")}
                 subtitle={t("An agile approach to innovation: discover your next market hit.")}
                 action={t("Contact Us")}
                 action_link={t("Learn more")}
                 workflow={InnovationWorkflow}
                href="/contact"/>
         </SectionAlternate>
       <Divider/>
           <Section>
             <Process
               title={t("People in Tech Leadership")}
               subtitle={t("We help you build teams that consistently deliver")}
               action={t("Contact Us")}
               href="/contact"
               process={PeopleInTechProcess}/>
           </Section>
         <Divider/>
         <Section>
             <Process
                 title={t("Technical Leadership")}
                 subtitle={t("We help you develop your new product with hands-on, qualified Technical Leadership that can engage business stakeholders")}
                 action={t("Contact Us")}
                 href="/contact"
                 process={TechleadProcess}/>
         </Section>
         <Divider/>
         <SectionAlternate>
            <Partners
                title={t("Technology Stack")}
                subtitle={t("We help you choose the right technology stack, hit the market first.")}
                label={t("Open Source")}
                action_link={t("Learn more")}
                partners={OpenSourceStacks}
                href="/contact"
            />
         </SectionAlternate>
         <Divider/>
         <Section>
             <ContactUs/>
         </Section>
     </WithLayout>
)
};

export default IndexPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["translation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;